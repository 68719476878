<template>
  <div
    class="row"
  >
    <div class="col-12 col-xl-8 col-xxl-6">
      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Produktgruppe
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                :value="kaufschutzRate.product.hn"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="HN / Produktgruppe"
                name="HN"
                placeholder="HN"
                :margin="false"
                required
                @input="findProduct"
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="kaufschutzRate.product.name"
                :margin="false"
                placeholder="Produktgruppe"
                name="Produktgruppe"
                required
              />
            </div>
          </div>
        </template>
      </ca-card>
      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Stammdaten
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-select
            v-model="kaufschutzRate.laufzeit"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Laufzeit"
            placeholder="bitte wählen"
            :options="laufzeitOptions"
            required
          />
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Zusatz
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-select
            v-model="kaufschutzRate.agbId"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="GB"
            required
            :options="agbOptions"
          />
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Eigenschaften
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <BasicCurrencyInput
                v-model="kaufschutzRate.carPriceFrom"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="Preis: von / bis"
                name="preisVon"
                placeholder="von"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <BasicCurrencyInput
                v-model="kaufschutzRate.carPriceTo"
                :margin="false"
                name="preisBis"
                placeholder="bis"
                required
              />
            </div>
          </div>
          <basic-select
            v-model="kaufschutzRate.carCondition"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Fahrzeugart"
            required
            :options="[
              {label: 'Gebrauchtwagen', value: 'USED'},
              {label: 'Neuwagen', value: 'NEW'}
            ]"
          />
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Kalkulation
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <BasicCurrencyInput
                v-model="kaufschutzRate.brutto"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="Brutto / Netto"
                name="Brutto"
                placeholder="Brutto"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                :value="formatPrice(kaufschutzRate.brutto / 1.19)"
                :margin="false"
                name="Netto"
                placeholder="Netto"
                disabled
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                :value="formatPercent(kaufschutzRate.brutto / kaufschutzRate.carPriceTo)"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="Prozent vom Preis"
                placeholder="Prozent vom Preis"
                disabled
                :margin="false"
              />
            </div>
          </div>
        </template>
      </ca-card>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import CaCard from '@/components/Card'
import BasicCurrencyInput from '@/components/BaseComponents/BasicCurrencyInput'
import formatPrice from '@/filters/formatPrice'
import formatPercent from '@/filters/formatPercent'
export default {
  components: {
    BasicInput,
    BasicSelect,
    BasicCurrencyInput,
    CaCard
  },
  props: {
    kaufschutzRate: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    agbs: [],
    laufzeitOptions: [
      { label: '12 Monate', value: 12 },
      { label: '24 Monate', value: 24 },
      { label: '36 Monate', value: 36 },
      { label: '48 Monate', value: 48 },
      { label: '60 Monate', value: 60 }
    ],
    products: []
  }),
  computed: {
    agbOptions () {
      return this.agbs.map(agb => ({ label: agb.file.name, value: agb._id }))
    }
  },
  created () {
    this.findAgbs()
    this.findProducts()
  },
  methods: {
    formatPrice,
    formatPercent,
    async findAgbs () {
      const agbs = await this.$store.dispatch('agbs/find', {
        query: {
          $sort: { createdAt: 1 },
          $client: {
            paginate: false
          }
        }
      })
      this.agbs = agbs
    },
    findProduct (hn) {
      if (this.products.length === 0) return
      this.productPending = true
      const product = this.getProductByHn(hn)
      if (product) {
        this.$set(this.kaufschutzRate, 'product', product)
      } else {
        this.$set(this.kaufschutzRate, 'product', {
          hn,
          name: '',
          conflictingProducts: []
        })
      }
    },
    getProductByHn (hn) {
      const product = this.products.find(product => product.hn.toString() === hn.toString())
      return product
    },
    async findProducts () {
      this.products = await this.$store.dispatch('products/find', {
        query: {
          $client: { paginate: false }
        }
      })
    }
  }
}
</script>

<style>

</style>
